/*----------------------
    Error Area
------------------------*/

.content-page-inner{
    height: fit-content;
    display: flex;
    align-items: center;
    min-height: 700px;
    padding-top: 120px;
    padding-bottom: 60px;
    .inner {
        text-align: left;
        h1, h2, h3, h4, h5 {
            color: #ffffff;
        }
        span {
            font-size: 16px;
            margin-top: 10px;
            color: #c6c9d8;
            opacity: .75;
            font-weight: 300;
            display: block;
            margin-bottom: 28px;
        }
    }
}
